<template>
  <div class="register clearfix">
    <div class="register-wrap">
      <el-card class="box-card">
        <el-form ref="registerForm" :model="user" status-icon>
          <h3 style="color: black">注册账号</h3>
          <el-form-item prop="name" label="用户名">
            <el-input v-model="user.name" placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item prop="email" label="邮箱">
            <div class="groupBox">
              <div class="groupBox_ipt">
                <el-input
                  v-model="user.email"
                  placeholder="请输入邮箱"
                ></el-input>
              </div>
              <div class="groupBox_btn">
                <el-button
                  v-if="!loadShow"
                  @click.prevent="getEmailCode(user.email)"
                  >获取验证码</el-button
                >
                <el-button v-else disabled>
                  <i class="el-icon-loading"></i>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {{ count }}
                  &nbsp;s&nbsp;&nbsp;&nbsp;
                </el-button>
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="code" label="验证码">
            <el-input v-model="user.code" placeholder="请输入验证码"></el-input>
          </el-form-item>
          <el-form-item prop="password" label="设置密码">
            <el-input
              v-model="user.password"
              show-password
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <el-form-item prop="confirmPassword" label="确认密码">
            <el-input
              v-model="user.confirmPassword"
              show-password
              placeholder="请输入确认密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="邀请代码">
            <el-input
              v-model="user.invitationCode"
              placeholder="请输入邀请代码(不必填)"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div style="text-align: right">
              <router-link to="/login">去登陆</router-link>
            </div>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" icon @click="doRegister()"
              >注册账号</el-button
            >
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
import { emailCode, register } from "@/api/user";
export default {
  name: "register",
  data() {
    return {
      count: 60,
      loadShow: false,
      user: {
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        invitationCode: "",
        code: "",
      },
    };
  },
  watch: {
    count(neaVal) {
      if (neaVal < 0) {
        clearInterval(this.timer);
        this.count = 60;
        this.loadShow = false;
      }
    },
  },
  created() {
    this.user.invitationCode = this.$route.params.invitationCode;
  },
  methods: {
    getEmailCode() {
      var vm = this;
      if (!vm.user.email) {
        vm.$message.error("请输入邮箱！");
        return;
      } else {
        var reg =
          /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        if (!reg.test(vm.user.email)) {
          vm.$message.error("请输入有效的邮箱！");
        } else {
          vm.loadShow = true;
          vm.count = 60;
          vm.timer = setInterval(() => {
            vm.count--;
          }, 1000);
          emailCode(vm.user.email).then((res) => {
            console.log(res);
            clearInterval(vm.timer);
            vm.count = 60;
            vm.loadShow = false;
          });
        }
      }
    },
    doRegister() {
      if (!this.user.name) {
        this.$message.error("请输入用户名！");
        return;
      } else if (!this.user.email) {
        this.$message.error("请输入邮箱！");
        return;
      } else if (!this.user.code) {
        this.$message.error("请输入验证码！");
        return;
      } else if (this.user.email != null) {
        var reg =
          /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        if (!reg.test(this.user.email)) {
          this.$message.error("请输入有效的邮箱！");
        } else if (!this.user.password) {
          this.$message.error("请输入密码！");
          return;
        } else if (!this.user.confirmPassword) {
          this.$message.error("请输入确认密码！");
          return;
        } else if (this.user.confirmPassword != this.user.password) {
          this.$message.error("两次密码不一致！");
          return;
        } else {
          let model = {
            name: this.user.name,
            confirmPassword: this.user.confirmPassword,
            email: this.user.email,
            emailCode: this.user.code,
            invitationCode: this.user.invitationCode,
            password: this.user.password,
          };
          register(model).then(() => {
            this.$message.success("注册成功，请登录！");
            this.$router.push({ path: "/login" });
          });
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
.register {
  width: 100%;
  height: 100vh;
  // background: url("../assets/images/bg.jpg") no-repeat;
  // background-size: cover;
  overflow: hidden;
}
.register-wrap {
  //background: url("../assets/images/ll.jpg") no-repeat;
  background-size: cover;
  width: 550px;
  margin: 10px auto;
  overflow: hidden;
  padding-top: 10px;
  line-height: 20px;
}

h3 {
  color: #0babeab8;
  font-size: 24px;
}
.groupBox {
  // display: flex;
  .groupBox_ipt {
    position: relative;
    // flex: 1;
    width: 75%;
    // float: left;
  }
  .groupBox_btn {
    // margin-left: 6px;
    position: absolute;
    top: 40px;
    right: 0;
  }
}
.el-button {
  width: 100%;
  margin-left: 0px;
}
</style>
